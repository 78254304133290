<template>
  <div>
    <!-- ON PC -->
    <div>
      <div>
        <p class="mb-0 small">
          {{ $t("greeting") }}, {{ UserData.fullname }}
        </p>
        <!-- <img
          src="https://laz-img-cdn.alicdn.com/tfs/TB1Yvbxh8HH8KJjy0FbXXcqlpXa-208-40.png"
          alt="verified"
          height="20px"
        > -->
      </div>

      <div
        v-if="UserData.ability.some(ele => ele.subject === 'information' || ele.subject === 'addressbook')"
        class="my-2"
      >
        <div>
          <b-link
            class="h4 mb-2"
            :to="{ name: 'profile' }"
          >
            <!-- <feather-icon
              icon="UserIcon"
              size="22"
            /> -->
            <img
              src="/iconMenu/usercus.svg"
              width="20px"
            >
            {{ $t("manageMyAccount") }}
          </b-link>
        </div>

        <div
          v-if="UserData.ability.some(ele => ele.subject === 'information')"
          class="pl-2 my-25"
        >
          <b-link :to="{ name: 'my-profile' }">
            <img
              src="/iconMenu/info.png"
              width="18px"
            > {{ $t("personalInfo") }}
          </b-link>
        </div>

        <div
          v-if="UserData.ability.some(ele => ele.subject === 'addressbook')"
          class="pl-2 my-25"
        >
          <b-link :to="{ name: 'profile-address' }">
            <img
              src="/iconMenu/receipt.svg"
              width="18px"
            > {{ $t("addressBook") }}
          </b-link>
        </div>

        <!-- <div class="pl-2 my-25">
        <b-link :to="{ name: 'voucher-wallet' }">
          โค้ดส่วนลดของฉัน
        </b-link>
      </div> -->
        <!--
      <div class="pl-2 my-25">
        <b-link :to="{name : 'anan-wallet'}">
          Anan wallet
        </b-link>
      </div> -->
      </div>
      <div
        v-if="UserData.ability.some(ele => ele.subject === 'order-list')"
        class="my-2"
      >
        <div>
          <b-link
            :to="{ name: 'order-list' }"
            class="h4 mb-2"
          >
            <img
              src="/iconMenu/order.svg"
              width="22px"
            > {{ $t("orderList") }}
          </b-link>
        </div>
      </div>
      <!-- <div class="my-2">
      <div>
        <b-link class="h4 mb-2">
          รายการสั่งซื้อของฉัน
        </b-link>
      </div>

      <div class="pl-2 my-25">
        <b-link> การคืนสินค้า </b-link>
      </div>

      <div class="pl-2 my-25">
        <b-link> การยกเลิกสินค้า </b-link>
      </div>
    </div> -->

      <div
        v-if="UserData.ability.some(ele => ele.subject === 'withdraw_money' || ele.subject === 'refill' ||ele.subject === 'transfer'|| ele.subject === 'alipay' || ele.subject === 'payfor'
          || ele.subject === 'balance')"
        class="my-2"
      >
        <div>
          <b-link class="h4 mb-2">
            <img
              src="/iconMenu/dashboard.svg"
              width="22px"
            > {{ $t("myFinances") }}
          </b-link>
        </div>

        <div
          v-if="UserData.ability.some(ele => ele.subject === 'refill')"
          class="pl-2 my-25"
        >
          <b-link :to="{ name: 'customer-refill' }">
            <img
              src="/iconMenu/coupon.svg"
              width="18px"
            > {{ $t("topupSystem") }}
          </b-link>
        </div>

        <div
          v-if="UserData.ability.some(ele => ele.subject === 'transfer')"
          class="pl-2 my-25"
        >
          <b-link :to="{ name: 'transfer-list' }">
            <img
              src="/iconMenu/finance-withdraw.svg"
              width="18px"
            > {{ $t("transferToChina") }}
          </b-link>
        </div>

        <div
          v-if="UserData.ability.some(ele => ele.subject === 'alipay')"
          class="pl-2 my-25"
        >
          <b-link :to="{ name: 'alipay-list' }">
            <img
              src="/iconMenu/alipay.svg"
              width="18px"
            > {{ $t("topupAlipay") }}
          </b-link>
        </div>

        <div
          v-if="UserData.ability.some(ele => ele.subject === 'payfor')"
          class="pl-2 my-25"
        >
          <b-link :to="{ name: 'payfor-list' }">
            <img
              src="/iconMenu/china-payfor.svg"
              width="18px"
            > {{ $t("DepositTaobao") }}
          </b-link>
        </div>

        <div
          v-if="UserData.ability.some(ele => ele.subject === 'balance')"
          class="pl-2 my-25"
        >
          <b-link :to="{ name: 'balance' }">
            <img
              src="/icon/bookbank.png"
              width="18px"
            > {{ $t("ledger") }}
          </b-link>
        </div>

        <!-- <div
          v-if="UserData.ability.some(ele => ele.subject === 'balance')"
          class="pl-2 my-25"
        >
          <b-link :to="{ name: 'balance' }">
            {{ $t("ledger") }}
          </b-link>
        </div> -->

        <div
          v-if="UserData.ability.some(ele => ele.subject === 'withdraw_money')"
          class="pl-2 my-25"
        >
          <b-link :to="{ name: 'withdraw' }">
            <img
              src="/iconMenu/china-exchange.svg"
              width="18px"
            > {{ $t("withdraw") }}
          </b-link>
        </div>
      </div>

      <div
        v-if="UserData.ability.some(ele => ele.subject === 'agent_user' || ele.subject === 'agent_tracking')"
        class="my-2"
      >
        <div>
          <b-link
            class="h4 mb-2"
          >
            <img
              src="/icon/user.png"
              width="22px"
            > {{ $t('key-331') }}
          </b-link>
          <div
            v-if="UserData.ability.some(ele => ele.subject === 'agent_user')"
            class="pl-2 my-25"
          >
            <b-link :to="{ name: 'member-user' }">
              <img
                src="/iconMenu/user.svg"
                width="18px"
              > {{ $t('key-332') }}
            </b-link>
          </div>

          <div
            v-if="UserData.ability.some(ele => ele.subject === 'agent_tracking')"
            class="pl-2 my-25"
          >
            <b-link :to="{ name: 'member-parcelcode' }">
              <img
                src="/iconMenu/runtrack.png"
                width="18px"
              > {{ $t('key-333') }}
            </b-link>
          </div>
        </div>
      </div>

      <div
        v-if="UserData.ability.some(ele => ele.subject === 'tracking')"
        class="my-2"
      >
        <div>
          <b-link
            :to="{ name: 'tracking' }"
            class="h4 mb-2"
          >
            <img
              src="/iconMenu/barcode.svg"
              width="22px"
            > {{ $t("parcelCode") }}
          </b-link>
        </div>
      </div>
      <div
        v-if="UserData.ability.some(ele => ele.subject === 'qc-postnumber')"
        class="my-2"
      >
        <div>
          <b-link
            :to="{ name: 'QC-user' }"
            class="h4 mb-2"
          >
            <img
              src="/iconMenu/QC.svg"
              width="22px"
            > ยืนยัน QC
          </b-link>
        </div>
      </div>
      <div
        v-if="UserData.ability.some(ele => ele.subject === 'checkbill')"
        class="my-2"
      >
        <div>
          <b-link
            :to="{ name: 'payment-create' }"
            class="h4 text-truncate mb-2"
            style="font-size: 17px;"
          >
            <img
              src="/iconMenu/role.svg"
              width="22px"
            > {{ $t("checkAndNotify") }}
          </b-link>
        </div>
      </div>
      <div
        v-if="UserData.ability.some(ele => ele.subject === 'exportproducts')"
        class="my-2"
      >
        <div>
          <b-link
            :to="{ name: 'export' }"
            class="h4 mb-2"
          >
            <img
              src="/icon/import.png"
              width="22px"
            > {{ $t("exportList") }}
          </b-link>
        </div>
      </div>

      <div
        v-if="UserData.ability.some(ele => ele.subject === 'lost_package')"
        class="my-2"
      >
        <div>
          <b-link
            :to="{ name: 'lost-package' }"
            class="h4 mb-2"
          >
            <img
              src="/icon/order.svg"
              width="22px"
            > {{ $t("reportMissingGoods") }}
          </b-link>
        </div>
      </div>

      <div
        v-if="UserData.ability.some(ele => ele.subject === 'goodsconfirm')"
        class="my-2"
      >
        <div>
          <b-link
            :to="{ name: 'goodsconfirm' }"
            class="h4 mb-2"
          >
            <img
              src="/iconMenu/order-no.svg"
              width="22px"
            > {{ $t("ownerlessGoods") }}
            <span
              v-if="notifyConfirm"
                class="notifys-badge2 pulse"
              >
                {{ notifyConfirm }}
              </span>
          </b-link>
        </div>
      </div>

      <div
        v-if="UserData.ability.some(ele => ele.subject === 'point')"
        class="my-2"
      >
        <div>
          <b-link
            :to="{ name: 'point' }"
            class="h4 mb-2"
          >
            <img
              src="/payment/ORD-2.svg"
              width="22px"
            > {{ $t("points") }}
          </b-link>
        </div>
      </div>

      <!-- <div class="my-2">
        <div>
          <b-link
            :to="{ name: 'user-commit-bill' }"
            class="h4 mb-2"
          >
            {{ $t("closeWarehouseJob") }}
          </b-link>
        </div>
      </div> -->

      <div
        v-if="UserData.ability.some(ele => ele.subject === 'notify')"
        class="my-2"
      >
        <div>
          <b-link
            :to="{ name: 'notice' }"
            class="h4 mb-2"
          >

            <img
              src="/iconMenu/megaphone.svg"
              width="22px"
            > {{ $t("announcement") }}
            <span
              v-if="notifycount"
              class="notify-badge pulse"
            >
              {{ notifycount }}
            </span>
          </b-link>

        </div>
      </div>

      <div
        v-if="UserData.ability.some(ele => ele.subject === 'line_notify')"
        class="my-2"
      >
        <div>
          <b-link
            :to="{ name: 'line-notify' }"
            class="h4 mb-2"
          >
            <img
              src="/icon/line.png"
              width="22px"
            > {{ $t("key-147") }} LINE Notify
          </b-link>
        </div>
      </div>

      <div
        v-if="UserData.ability.some(ele => ele.subject === 'questions')"
        class="my-2"
      >
        <div>
          <b-link
            :to="{ name: 'faq' }"
            class="h4 mb-2"
          >
            <img
              src="/iconMenu/faq.svg"
              width="22px"
            > {{ $t("faq") }}
          </b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BLink,
  //   BButton,
  //   BSidebar,
  VBToggle,
//   BDropdown,
//   BDropdownItem,
  // AppCollapseItemMargin,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  name: 'SideBar',
  components: {
    BLink,
    // BButton,
    // BSidebar,
    // AppCollapse,
    // AppCollapseItem,
    // BDropdown,
    // BDropdownItem,
    // AppCollapseItemMargin,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      // Loader
      locale: this.$i18n.locale,
      selectedLang: '',
      UserData: JSON.parse(localStorage.getItem('userData')),
      loading: true,
      notifycount: 0,
      notifyConfirm: 0,
    }
  },
  computed: {
    MobileMenu() {
      return [
        {
          items: [{
            Active: this.$route.name === 'new-pages' ? 'gradient-primary' : '',
            icon: 'HomeIcon',
            label: this.$t('key-176'),
            link: 'new-pages',
          },
          ],
        },
        {
          items: [{
            Active: this.$route.name === 'order-list' ? 'gradient-primary' : '',
            icon: 'ShoppingCartIcon',
            label: this.$t('orderList'),
            link: 'order-list',
          },
          ],
        },
        {
          items: [{
            Active: this.$route.name === 'payment-create' ? 'gradient-primary' : '',
            icon: 'ArchiveIcon',
            label: this.$t('checkStatus'),
            link: 'payment-create',
          }],
        },
        {
          items: [{
            Active: this.$route.name === 'export' ? 'gradient-primary' : '',
            icon: 'SendIcon',
            label: this.$t('exportRecords'),
            link: 'export',
          }],
        },
        {
          items: [{
            Active: this.$route.name === 'tracking' ? 'gradient-primary' : '',
            icon: 'BoxIcon',
            label: this.$t('parcelCode'),
            link: 'tracking',
          }],
        },
        {
          items: [{
            Active: this.$route.name === 'goodsconfirm' ? 'gradient-primary' : '',
            icon: 'DeleteIcon',
            label: this.$t('ownerlessGoods'),
            link: 'goodsconfirm',
          }],
        },
        {
          title: this.$t('manageMyAccount'),
          icon: 'ChevronRightIcon',
          items: [
            {
              Active: this.$route.name === 'my-profile' ? 'gradient-primary' : '',
              icon: 'ChevronRightIcon',
              label: this.$t('personalInfo'),
              link: 'my-profile',
            },
            {
              Active: this.$route.name === 'profile-address' ? 'gradient-primary' : '',
              icon: 'ChevronRightIcon',
              label: this.$t('addressBook'),
              link: 'profile-address',
            },
          ],
        },
        {
          title: this.$t('myFinances'),
          icon: 'ChevronRightIcon',
          items: [
            {
              Active: this.$route.name === 'customer-refill' ? 'gradient-primary' : '',
              icon: 'ChevronRightIcon',
              label: this.$t('topupSystem'),
              link: 'customer-refill',
            },
            {
              Active: this.$route.name === 'transfer-list' ? 'gradient-primary' : '',
              icon: 'ChevronRightIcon',
              label: this.$t('transferToChina'),
              link: 'transfer-list',
            },
            {
              Active: this.$route.name === 'alipay-list' ? 'gradient-primary' : '',
              icon: 'ChevronRightIcon',
              label: this.$t('topupAlipay'),
              link: 'alipay-list',
            },
            {
              Active: this.$route.name === 'payfor-list' ? 'gradient-primary' : '',
              icon: 'ChevronRightIcon',
              label: this.$t('DepositTaobao'),
              link: 'payfor-list',
            },
            {
              Active: this.$route.name === 'balance' ? 'gradient-primary' : '',
              icon: 'ChevronRightIcon',
              label: this.$t('ledger'),
              link: 'balance',
            },
            {
              Active: this.$route.name === 'withdraw' ? 'gradient-primary' : '',
              icon: 'ChevronRightIcon',
              label: this.$t('withdraw'),
              link: 'withdraw',
            },
          ],
        },
      ]
    },
    BaseWsUrl() {
      return this.$store.state.app.BaseWsUrl
    },
    WsMain() {
      return this.$store.state.app.WsMain
    },
    WsNotifycount() {
      return this.$store.state.app.WsNotifycount
    },
  },
  mounted() {
    this.CheckWsConnect()
    this.getData()
    setTimeout(() => {
      this.loading = false
    }, 1000)
  },

  methods: {
    async CheckWsConnect() {
      if (!this.WsMain) {
        await this.ConnectWS()
      }
      if (!this.WsNotifycount) {
        await this.SubWsOncountNoti()
        this.WsOnNoticount()
      } else {
        this.WsOnNoticount()
      }
    },
    ConnectWS() {
      const Token = localStorage.getItem('token')
      if (Token) {
        // const ws = Ws('wss://api.anan-cargo.com')
        const ws = Ws(this.BaseWsUrl)
        ws.withJwtToken(Token).connect()

        ws.on('open', () => {
          console.log('Connection established')
        })
        ws.on('error', error => {
          console.log('Connection Error', error)
        })

        ws.on('close', event => {
          console.log('Connection closed', event)
        })
        this.$store.commit('app/SET_WSMAIN', ws)
      }
    },
    SubWsOncountNoti() {
      const UserData = JSON.parse(localStorage.getItem('userData'))
      const WsCount = this.WsMain.subscribe(`NotifyCount:${UserData.userID}`)
      this.$store.commit('app/SET_WSNOTIFYCOUNT', WsCount)
    },
    WsOnNoticount() {
      this.WsNotifycount.on('open', () => {
        console.log('CartWs established')
      })
      this.WsNotifycount.emit('message', ' ')
      this.WsNotifycount.on('CountNotice', message => {
        this.notifycount = message || 0
      })
      this.WsNotifycount.on('CountConfirm', message => {
        this.notifyConfirm = message || 0
      })
      this.WsNotifycount.on('error', error => {
        console.log('Cart Error', error)
      })
    },
    updateSelectedText(text, lang) {
      this.selectedLang = text
      this.$i18n.locale = lang
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })

      this.onShow = localStorage.getItem('token') !== null
    },
    async getData() {
      try {
        const { data: res } = await this.$http.get('/Profilecustomer/show')
        this.items = res
        // res.forEach(ele => {
        //   this.items.id = ele.id
        //   this.items.email = ele.email
        //   this.items.fullname = ele.fullname
        //   this.items.line_id = ele.line_id
        //   this.items.tel = ele.tel
        //   this.items.username = ele.username
        // })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.menu {
  list-style-type: none;
  padding: 0;
}

.menu li {
  padding: 3px 16px;
  display: flex;
  align-items: center;
}

.notify-badge {
    font-family: "Poppins", sans-serif;
    padding: 6px;
    background: #FF3838;
    text-align: center;
    border-radius: 50%;
    color: white;
    font-size: 0.786rem;
    line-height: 0.786;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px; /* ปรับให้เท่ากับ height */
    height: 20px; /* ปรับให้เท่ากับ width */
}

</style>
