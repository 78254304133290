<template>
  <div>
    <div class="anan-set-nav-conttainer mt-150">
      <div class="row">
        <div class="col-md-2 col-lg-2 col-sm-12">
          <side-bar class="d-none d-md-block" />
        </div>

        <div class="col-md-10 col-lg-10 col-sm-12">
          <b-button
            class="btn btn-gradient-primary mb-1 mr-50"
            @click="$router.go(-1)"
          >
            <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
          </b-button>
          <b-button
            class="btn btn-gradient-success mb-1 ml-25 mr-50"
            @click="Submit()"
          >
            <i class="far fa-save mr-50" /> {{ $t('saveData') }}
          </b-button>
          <div class="row">

            <div class="col-md-5 col-lg-6 col-sm-12">
              <validation-observer ref="simpleRules">
                <div class="card">
                  <div class="card-header">
                    <h3>{{ $t('personalInfo') }}</h3>
                  </div>
                  <div class="card-body">
                    <b-form-group
                      label-for="basic-addon3"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                      class="mb-50"
                    >
                      <template v-slot:label>
                        <span class="text-danger">*</span> {{ $t('customerCode') }}
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="h-user"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :prepend="`${userData.username}-`"
                        >

                          <b-form-input
                            id="h-user"
                            v-model="items.user_member"
                            type="text"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label-for="basic-addon3"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                      class="mb-50"
                    >
                      <template v-slot:label>
                        <span class="text-danger">*</span> {{ $t('key-77') }}
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="h-fullName"
                        rules="required"
                      >

                        <b-form-input
                          id="h-fullName"
                          v-model="items.fullname"
                          type="text"
                          placeholder=""
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label-for="basic-addon3"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                      class="mb-50"
                    >
                      <template v-slot:label>
                        <span class="text-danger">*</span> {{ $t('key-80') }}
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="h-email"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                        >

                          <b-form-input
                            id="h-email"
                            v-model="items.email"
                            type="text"
                            placeholder=""
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label-for="basic-addon3"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                      class="mb-50"
                    >
                      <template v-slot:label>
                        <span class="text-danger">*</span> {{ $t('key-81') }}
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="h-phone"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                        >

                          <b-form-input
                            id="h-phone"
                            v-model="items.tel"
                            type="number"
                            placeholder=""
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label-for="basic-addon3"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                      class="mb-50"
                    >
                      <template v-slot:label>
                        {{ $t('mnemonic') }}
                      </template>
                      <b-input-group
                        class="input-group-merge"
                      >

                        <BFormTextarea
                          id="basic-addon3"
                          v-model="items.remarks"
                          type="number"
                          placeholder=""
                        />
                      </b-input-group>

                    </b-form-group>

                  </div>
                </div>
              </validation-observer>
              <div class="card">
                <div class="card-header flex">
                  <h3>{{ $t('address') }}</h3>
                  <button
                    class="btn btn-gradient-primary btn-sm"
                    @click="editaddress()"
                  >
                    <i class="fas fa-plus mr-25" /> {{ $t('addAddress') }}
                  </button>
                </div>
                <div class="card-body">
                  <b-table
                    striped
                    hover
                    responsive
                    :items="items.address"
                    :fields="fields_addr"
                    show-empty
                    :table-class="{'t-width-addr': items.address.length > 0}"
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner
                          label="กำลังโหลด..."
                          variant="danger"
                        />
                      </div>
                    </template>

                    <template #empty>
                      <div class="text-center">
                        <img
                          src="@/assets/images/anan-img/empty/empty.png"
                          alt="empty"
                          width="180px"
                        >
                        <p>No Data</p>
                      </div>
                    </template>

                    <template #cell(action)="data">
                      <feather-icon
                        icon="Edit3Icon"
                        class="mr-1 text-primary cursor-pointer"
                        @click="editaddress(data.item)"
                      />
                      <feather-icon
                        class="text-danger cursor-pointer"
                        icon="TrashIcon"
                        @click="Deleteitem(data.item)"
                      />
                    </template>
                  </b-table>
                </div>
              </div>

            </div>

            <div class="col-md-2 col-lg-6 col-sm-12">

              <div class="card">
                <div class="card-header">
                  <h3>{{ $t('userGroup') }}</h3>
                </div>
                <div class="card-body">
                  <b-table
                    striped
                    hover
                    responsive
                    :items="items_car"
                    :fields="fields_Car"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner
                          label="กำลังโหลด..."
                          variant="danger"
                        />
                      </div>
                    </template>

                    <template #empty>
                      <div class="text-center">
                        <img
                          src="@/assets/images/anan-img/empty/empty.png"
                          alt="empty"
                          width="180px"
                        >
                        <p>No Data</p>
                      </div>
                    </template>
                    <template #cell(product_type_name)="data">
                        {{ checkproduct_type(data.item) }}
                    </template>
                    <template #cell(kilo)="data">
                      <validation-provider
                        #default="{ errors }"
                        name="h-kilo"
                        rules="required"
                      >
                        <b-form-input
                          id="h-kilo"
                          v-model="data.item.kilo"
                          type="number"
                          :state="errors.length === 1 ? false: Number(data.item.kilo) < data.item.product_type_kilo ? false: null"
                        />
                        <span class="text-danger">{{ errors[0] ? $t('requiredField') : Number(data.item.kilo) < data.item.product_type_kilo ? `กรอกมากกว่า ${data.item.product_type_kilo}`: '' }}</span>
                      </validation-provider>
                    </template>

                    <template #cell(queue)="data">
                      <validation-provider
                        #default="{ errors }"
                        name="h-queue"
                        rules="required"
                      >
                        <b-form-input
                          id="h-queue"
                          v-model="data.item.queue"
                          type="number"
                          :state="errors.length === 1 ? false:Number(data.item.queue) < data.item.product_type_queue ? false: null"
                        />
                        <span class="text-danger">{{ errors[0] ? $t('requiredField') : Number(data.item.queue) < data.item.product_type_queue ? `กรอกมากกว่า ${data.item.product_type_queue}`: '' }}</span>
                      </validation-provider>
                    </template>

                  </b-table>

                  <b-table
                    striped
                    hover
                    responsive
                    :items="items_ship"
                    :fields="fields_ship"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner
                          label="กำลังโหลด..."
                          variant="danger"
                        />
                      </div>
                    </template>

                    <template #empty>
                      <div class="text-center">
                        <img
                          src="@/assets/images/anan-img/empty/empty.png"
                          alt="empty"
                          width="180px"
                        >
                        <p>No Data</p>
                      </div>
                    </template>
                    <template #cell(product_type_name)="data">
                        {{ checkproduct_type(data.item) }}
                    </template>
                    <template #cell(kilo)="data">
                      <validation-provider
                        #default="{ errors }"
                        name="h-kilo2"
                        rules="required"
                      >
                        <b-form-input
                          id="h-kilo2"
                          v-model="data.item.kilo"
                          type="number"
                          :state="errors.length === 1 ? false: Number(data.item.kilo) < data.item.product_type_kilo ? false: null"
                        />
                        <span class="text-danger">{{ errors[0] ? $t('requiredField') : Number(data.item.kilo) < data.item.product_type_kilo ? `กรอกมากกว่า ${data.item.product_type_kilo}`: '' }}</span>
                      </validation-provider>
                    </template>

                    <template #cell(queue)="data">
                      <validation-provider
                        #default="{ errors }"
                        name="h-queue2"
                        rules="required"
                      >
                        <b-form-input
                          id="h-queue2"
                          v-model="data.item.queue"
                          type="number"
                          :state="errors.length === 1 ? false:Number(data.item.queue) < data.item.product_type_queue ? false: null"
                        />
                        <span class="text-danger">{{ errors[0] ? $t('requiredField') : Number(data.item.queue) < data.item.product_type_queue ? `กรอกมากกว่า ${data.item.product_type_queue}`: '' }}</span>
                      </validation-provider>
                    </template>

                  </b-table>
                </div>
              <!-- </div> -->

              </div>

            </div>

          </div>
        </div>
      </div>

      <b-modal
        id="add-addr"
        :title="$t('addNewAddress')"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        @ok.prevent="addAddr()"
      >
        <validation-observer ref="addRules">
          <b-form-group
            label-for="h-name"
            :label="$t('key-77')"
          >
            <validation-provider
              #default="{ errors }"
              name="Full Name"
              rules="required"
            >
              <b-form-input
                id="h-name"
                v-model="name_add"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-phone-number"
            :label="$t('key-146')"
          >
            <validation-provider
              #default="{ errors }"
              name="Phone Number"
              rules="required"
            >
              <b-form-input
                id="h-phone-number"
                v-model="phoneNumber"
                type="number"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-address"
            :label="$t('addressNumber')"
          >
            <validation-provider
              #default="{ errors }"
              name="Address"
              rules="required"
            >
              <b-form-textarea
                id="h-address"
                v-model="address"
                row="2"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-province-name"
            :label="$t('province')"
          >
            <validation-provider
              #default="{ errors }"
              name="Province"
              rules="required"
            >
              <v-select
              class="style-chooser"
                id="h-province-name"
                v-model="selectedProvince"
                label="province_name"
                :options="provinceOptions"
                :state="errors.length > 0 ? false : null"
                :reduce="ele => ele.province_id"
                @input="updateDistricts"
              />
              <small class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-district-name"
            :label="$t('district')"
          >
            <validation-provider
              #default="{ errors }"
              name="District"
              rules="required"
            >
              <v-select
              class="style-chooser"
                id="h-district-name"
                v-model="selectedDistrict"
                label="district_name"
                :options="filteredDistricts"
                :state="errors.length > 0 ? false : null"
                :reduce="ele => ele.district_id"
                @input="updateSubDistricts"
              />
              <small class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-sub-district-name"
            :label="$t('subdistrict')"
          >
            <validation-provider
              #default="{ errors }"
              name="Sub District"
              rules="required"
            >
              <!-- @click=";(subDistrict = null), (postalCode = null)" -->
              <v-select
              class="style-chooser"
                id="h-sub-district-name"
                v-model="selectedSubDistrict"
                label="sub_district_name"
                :options="filteredSubDistricts"
                :state="errors.length > 0 ? false : null"
                :reduce="ele => ele.tumbon_id"
                @input="updatePostalcode"
              />
              <small class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-postal-code"
            :label="$t('postalCode')"
          >
            <validation-provider
              #default="{ errors }"
              name="Postal Code"
              rules="required"
            >
              <b-form-input
                id="h-postal-code"
                v-model="postalcode"
                label="zipcode"
                :state="errors.length > 0 ? false : null"
                :disabled="postalcode"
              />
              <small class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-modal>

    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormTextarea,
  BTable,
  BModal,
} from 'bootstrap-vue'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import SideBar from '../component/SideBar.vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    SideBar,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    BTable,
    BModal,
    vSelect,
  },

  data() {
    return {
      required,
      // -----------------------
      // ส่วนของ "ข้อมูลส่วนตัว"
      userID: null,
      fullName: null,
      Email: null,
      phone: null,
      valee: null,
      // -----------------------
      // ส่วนของ Modal "เพิ่ม,แก้ไข ที่อยู่"
      nameAddr: null,
      phoneAddr: null,
      Addr: null,
      subDistrict: null,
      district: null,
      province: null,
      zipcode: null,

      // -----------------------

      titleAddr: null,
      items_car: [
        // {
        //   id: 'A',
        //   kilo: '15',
        //   queue: '6500',
        // },
        // {
        //   id: 'B',
        //   kilo: '15',
        //   queue: '6500',
        // },
        // {
        //   id: 'C',
        //   kilo: '15',
        //   queue: '6500',
        // },
      ],

      items_ship: [
        // {
        //   id: 'A',
        //   kilo: '15',
        //   queue: '6500',
        // },
        // {
        //   id: 'B',
        //   kilo: '15',
        //   queue: '6500',
        // },
        // {
        //   id: 'C',
        //   kilo: '15',
        //   queue: '6500',
        // },
      ],
      items: null,
      items_addr: [
        // {
        //   fullName: 'มาโนช ลุยสวน',
        //   phone: '0888888888',
        //   province: 'ลพ',
        //   zipcode: '65000',
        //   address: '123/3',
        // },
      ],
      ID: this.$route.params.id,
      product_type: [],
      items_carfix: [],
      itemspvc: [],
      idistrict: [],
      iSubdistrict: [],
      izipcode: [],
      selectedProvince: null,
      selectedDistrict: null,
      selectedProvince: null,
      selectedSubDistrict: null,
      postalcode: null,
      name_add: null,
      phoneNumber: null,
      address: null,
      id: null,
      remarks: null,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },

  computed: {
    fields_Car() {
      return [
        {
          key: 'product_type_name', label: this.$t('truck'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'kilo', label: this.$t('kilo'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'queue', label: this.$t('cubic'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },

    fields_ship() {
      return [
        {
          key: 'product_type_name', label: this.$t('ship'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'kilo', label: this.$t('kilo'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'queue', label: this.$t('cubic'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },

    fields_addr() {
      return [
        {
          key: 'fullname', label: this.$t('key-77'), sortable: false, thStyle: { width: '20%', fontSize: '10px' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'phoneNumber', label: this.$t('key-81'), sortable: false, thStyle: { width: '20%', fontSize: '10px' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'province', label: this.$t('province'), sortable: false, thStyle: { width: '20%', fontSize: '10px' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'postalcode', label: this.$t('postalCode'), sortable: false, thStyle: { width: '10%', fontSize: '10px' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'address', label: this.$t('address'), sortable: false, thStyle: { width: '15%', fontSize: '10px' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '15%', fontSize: '10px' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
    provinceOptions() {
      return this.itemspvc.map(item => ({
        province_id: item.province_id,
        province_name: item.province_name,
      }))
    },
    filteredDistricts() {
      if (this.selectedProvince) {
        return this.idistrict.filter(ele => (ele.province_id === this.selectedProvince))
          .map(item => ({
            district_id: item.district_id,
            district_name: item.district_name,
            province_id: item.province_id,
          }))
      }
      return this.idistrict.map(item => ({
        district_id: item.district_id,
        district_name: item.district_name,
        province_id: item.province_id,
      }))
    },
    filteredSubDistricts() {
      if (this.selectedDistrict) {
        return this.iSubdistrict.filter(ele => (ele.ampur_id === this.selectedDistrict))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      if (this.selectedProvince) {
        return this.iSubdistrict.filter(ele => (ele.changwat_id === this.selectedProvince))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      return this.iSubdistrict.map(item => ({
        ampur_id: item.ampur_id,
        sub_district_name: item.sub_district_name,
        tumbon_id: item.tumbon_id,
        changwat_id: item.changwat_id,
      }))
    },
    filteredpostalCode() {
      if (this.selectedSubDistrict) {
        return this.izipcode.filter(ele => (ele.tum_id === this.selectedSubDistrict))
          .map(item => ({
            tum_id: item.tum_id,
            zipcode: item.zipcode,
          }))
      }
      return this.izipcode.map(item => ({
        tum_id: item.tum_id,
        zipcode: item.zipcode,
      }))
    },
  },
  async mounted() {
    await this.getProvince()
    await this.getDistrict()
    await this.getSubdistrict()
    await this.getzipcode()
    this.GetData()
  },
  methods: {
    async GetData() {
      try {
        const params = {
          ID: this.ID,
        }
        const { data: res } = await this.$http.get('Usermember/GetMemberOne', { params })
        this.items = res.data
        this.Getgroup()
      } catch (err) {
        console.log(err)
      }
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const index = this.product_type.findIndex(item => Number(item.kilo) < item.product_type_kilo);
          if (index !== -1) {
              return
          }
          this.product_type.forEach(item => {
              item.product_type_kilo = Number(item.kilo)
              item.product_type_queue = Number(item.queue)
              delete item.kilo;
              delete item.queue;
            });
          const obj = {
            ID: this.items._id,
            agent_id: this.items.agent_id,
            email: this.items.email,
            fullname: this.items.fullname,
            remarks: this.items.remarks,
            tel: this.items.tel,
            user_member: this.items.user_member,
            address: this.items.address,
            product_type: this.product_type
          }
          this.$http.post('Usermember/StoreAgent', obj)
            .then(() => {
              this.GetData()
              this.Success(`${this.$t('key-260')}`)
            })
            .catch(err => {
              console.log(err.response.data.error)
              this.SwalError(err.response.data.error)
            })
        }
      })
    },
    checkproduct_type(item) {
      if (item) {
        if (this.$i18n.locale === 'th') {
       return item.product_type_name
      } else if (this.$i18n.locale === 'en') {
        return item.product_type_name_en
      } else if (this.$i18n.locale === 'cn') {
        return item.product_type_name_cn
      }
      }
      
    },
    editaddress(data) {
      if (data) {
        this.id = data._id
        this.name_add = data.fullname
        this.phoneNumber = data.phoneNumber
        this.address = data.address
        this.selectedProvince = data.province_id
        this.selectedDistrict = data.district_id
        this.selectedSubDistrict = data.subdistrict_id
        this.postalcode = data.postalcode
      } else {
        this.id = null
        this.name_add = null
        this.phoneNumber = null
        this.address = null
        this.selectedProvince = null
        this.selectedDistrict = null
        this.selectedSubDistrict = null
        this.postalcode = null
      }
      // this.$refs['add-addr'].show()
      this.$bvModal.show('add-addr')
      // this.titleAddr = this.$t('addAddress')
    },
    async Getgroup() {
      try {
        const { data: res } = await this.$http.get('groupbyuser/show')
        if (res.success) {
          res.data.product_type.sort((a, b) => {
            if (a.product_type_initial < b.product_type_initial) return -1
            if (a.product_type_initial > b.product_type_initial) return 1
          })
          
          if (this.items.product_type.length) {
            res.data.product_type = res.data.product_type.map(ele => {
                const data = this.items.product_type.find(item => item._id == ele._id);
                return {
                    ...ele,
                    kilo: data.product_type_kilo,
                    queue: data.product_type_queue,
                    product_type_queue: ele.product_type_queue,
                    product_type_kilo: ele.product_type_kilo,
                };
            });
            this.product_type = res.data.product_type 
            this.items_car =  res.data.product_type .filter(ele => ele.product_type_class === 1)
            this.items_ship =  res.data.product_type .filter(ele => ele.product_type_class === 2)
            
          } else {
            res.data.product_type = res.data.product_type.map(ele => ({
            ...ele,
            kilo: ele.product_type_kilo,
            queue: ele.product_type_queue,
          }))
            this.product_type = res.data.product_type
            this.items_car = res.data.product_type.filter(ele => ele.product_type_class === 1)
            this.items_ship = res.data.product_type.filter(ele => ele.product_type_class === 2)
          }
          
          
        }
      } catch (err) {
        console.log(err)
      }
    },
    addAddr() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          const obj = {
            agent_id: this.ID,
            ID: this.id,
            fullname: this.name_add,
            phoneNumber: this.phoneNumber,
            address: this.address,
            province: '',
            district: '',
            subdistrict: '',
            postalcode: this.postalcode,
            province_id: this.selectedProvince,
            district_id: this.selectedDistrict,
            subdistrict_id: this.selectedSubDistrict,
          }
          if (this.selectedProvince) {
            const provi = this.itemspvc.find(ele => (ele.province_id === this.selectedProvince))
            obj.province = provi.province_name
          }
          if (this.selectedDistrict) {
            const arr = this.idistrict.find(ele => (ele.district_id === this.selectedDistrict))
            obj.district = arr.district_name
          }
          if (this.selectedSubDistrict) {
            const arr = this.iSubdistrict.find(ele => (ele.tumbon_id === this.selectedSubDistrict))
            obj.subdistrict = arr.sub_district_name
          }
          this.$http.post('Usermember/UpdateAddress', obj)
            .then(() => {
              this.GetData()
              this.Success('เพิ่มที่อยู่สำเร็จ')
              this.$bvModal.hide('add-addr')
            })
            .catch(err => {
              this.SwalError('เพิ่มที่อยู่ไม่สำเร็จ')
              console.log(err)
            })
        }
      })
    },

    // EditItem(data) {
    //   this.$refs['modal-Addr'].show()
    //   this.titleAddr = this.$t('key-324')
    // },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    async getProvince() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/province') // จังหวัด
        this.itemspvc = res
      } catch (e) {
        console.log(e)
      }
    },
    async getDistrict() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/district') // อำเภอ
        this.idistrict = res
      } catch (e) {
        console.log(e)
      }
    },
    async getSubdistrict() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/Subdistrict') // ตำบล
        this.iSubdistrict = res
      } catch (e) {
        console.log(e)
      }
    },
    async getzipcode() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/zipcode') // ตำบล
        this.izipcode = res
      } catch (e) {
        console.log(e)
      }
    },
    updateDistricts() {
      this.selectedDistrict = null
      this.selectedSubDistrict = null
    },
    updateSubDistricts() {
      this.selectedSubDistrict = null
      this.postalcode = null
    },
    updatePostalcode() {
      const arr = this.izipcode.find(ele => (ele.tum_id === this.selectedSubDistrict))
      this.postalcode = arr.zipcode
    },
    Deleteitem(data) {
      this.$bvModal.msgBoxConfirm('ต้องการลบข้อมูลหรือไม่ ?', {
        title: this.$t('confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          const obj = {
            ID: data._id,
            agent_id: this.ID,
          }
          this.$http.post('Usermember/DeleteAddress', obj)
            .then(() => {
              this.GetData()
              this.Success('ลบที่อยู่สำเร็จ')
            })
            .catch(err => {
              this.SwalError('ลบที่อยู่ไม่สำเร็จ')
              console.log(err)
            })
        }
      })
    },
  },

}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
    <style>
      .style-chooser .vs__dropdown-menu {
  max-height: 180px;
  overflow-y: auto;
}

    .card .card-header {
        padding-top: 15px !important;
        padding-bottom: 5px !important;
    }

    .card-header {
        border-bottom: 1px solid #e5e5e5 !important;
    }

    .card-body {
        margin-top: 20px !important;
    }

    .t-width-addr{
        min-width: 700px !important;
    }

    </style>
