<template>
  <div>
    <!-- <MenuMobile class="d-md-none" /> -->
    <MenuPc class="d-none d-md-block" />
  </div>
</template>

<script>
// import MenuMobile from './MenuMobile.vue';
import MenuPc from './MenuPc.vue';

export default {
  name: 'SideBar',
  components: {
    // MenuMobile,
    MenuPc,
  },
}
</script>
